import type { RouteRecordRaw } from 'vue-router'
import AppUa from './app.ua'
import AppUserFeedback from './app.user.feedback'

const Layout = () => import('@/layouts/index.vue')

const routes: RouteRecordRaw = {
  path: '/app',
  component: Layout,
  // redirect: '/user/list',
  name: 'App',
  meta: {
    title: 'App',
    icon: 'carbon:application-mobile',
  },
  children: [
    AppUa,
    AppUserFeedback,
  ],
}

export default routes
