import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layouts/index.vue')

const routes: RouteRecordRaw = {
  path: '/user/feedback',
  component: () => import('@/views/app/user_feedback/list.vue'),
  name: 'AppUserFeedback',
  meta: {
    title: '用户反馈',
    icon: 'carbon:user-feedback',
  },
}

export default routes
