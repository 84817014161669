import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layouts/index.vue')

const routes: RouteRecordRaw = {
  path: '/user',
  component: Layout,
  redirect: '/user/list',
  name: 'user',
  meta: {
    title: '用户',
    // icon: 'humbleicons:user',
    icon: 'ep:user',
  },
  children: [
    {
      path: 'list',
      name: 'userList',
      component: () => import('@/views/user/list.vue'),
      meta: {
        title: '用户管理',
        icon: 'humbleicons:user',
      },
    },
    {
      path: 'create',
      name: 'userCreate',
      component: () => import('@/views/user/detail.vue'),
      meta: {
        title: '新增',
        sidebar: false,
        activeMenu: '/user/list',
      },
    },
    {
      path: 'edit/:id',
      name: 'userEdit',
      component: () => import('@/views/user/detail.vue'),
      meta: {
        title: '详情',
        sidebar: false,
        activeMenu: '/user/list',
      },
    },
    {
      path: 'health/:id',
      name: 'userHealth',
      component: () => import('@/views/user/detail.vue'),
      meta: {
        title: '健康数据',
        sidebar: false,
        activeMenu: '/user/list',
      },
    },
    {
      path: 'complex/list',
      name: 'UserComplexList',
      component: () => import('@/views/user/complex_list.vue'),
      meta: {
        title: '深度用户查询',
        icon: 'material-symbols:group-search-outline',
      },
    },
  ],
}

export default routes
