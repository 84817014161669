import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layouts/index.vue')

const routes: RouteRecordRaw = {
  path: '/health_metrics',
  component: Layout,
  // redirect: '/user/list',
  name: 'HealthMetrics',
  meta: {
    title: '健康数据',
    icon: 'healthicons:health-data-security2x-outline',
  },
  children: [
    {
      path: '/health_metrics/list',
      name: 'healthMetricsRecordList',
      component: () => import('@/views/health_metrics/record.list.vue'),
      meta: {
        title: '健康记录查询',
        icon: 'icon-park-outline:history-query',
      },
    },
    {
      path: '/health_metrics/user/report/:id',
      name: 'HealthMetricsUserReport',
      component: () => import('@/views/health_metrics/user.report.vue'),
      meta: {
        title: '用户健康报告',
        icon: 'icon-park-outline:history-query',
      },
    },
  ],
}

export default routes
